@import 'assets/stylesheets/commons';

$not-found-background-color: $base-gray-lighter-color;
$not-found-title-color: $base-greyish-brown;
$not-found-subtitle-color: $base-purple-color;

.notFound {
  background: $not-found-background-color;

  &Container {
    @include brokenships-container($container-max-width);

    padding-top: 180px;
    padding-bottom: 100px;
  }

  &Image {
    display: block;

    margin: 0 auto;
  }

  &Title {
    font-size: 34px;
    font-weight: 300;

    margin-bottom: 15px;

    text-align: center;

    color: $not-found-title-color;
  }

  &Subtitle {
    font-size: 18px;

    margin: 0;

    text-align: center;
    letter-spacing: 0.5px;

    color: $not-found-subtitle-color;
  }
}
